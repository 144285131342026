import React, { Component } from 'react';
import { app, dialog } from "@microsoft/teams-js";
// import * as AdaptiveCards from "adaptivecards";

import httpHelpers from './httpHelpers.js';
import "./AdaptiveCardProxy.css"


const BOT_ACTION_NAME = {
    TASK_FETCH: "task/fetch",
    TAB_FETCH: "tab/fetch"
};

const loadingCard = {
    "type": "AdaptiveCard",
    "$schema": "https://AdaptiveCards.io/schemas/adaptive-card.json",
    "version": "1.5",
    "body": [
        {
            "type": "Image",
            "url": "https://app.centro.rocks/loading.gif"
        }
    ]
};
const errorCard = {
    "type": "AdaptiveCard",
    "version": "1.5",
    "body": [
        {
            "type": "TextBlock",
            "text": "App Offline",
            "wrap": true,
            "style": "heading"
        },
        {
            "type": "TextBlock",
            "text": "Check your network connection and try again.",
            "wrap": true
        }
    ],
    "$schema": "https://adaptivecards.io/schemas/adaptive-card.json"
};

const lightHostConfig = {
    "choiceSetInputValueSeparator": ",",
    "supportsInteractivity": true,
    "spacing": {
        "small": 8,
        "default": 12,
        "medium": 16,
        "large": 20,
        "extraLarge": 24,
        "padding": 16
    },
    "separator": {
        "lineThickness": 1,
        "lineColor": "#EEEEEE"
    },
    "imageSizes": {
        "small": 32,
        "medium": 52,
        "large": 100
    },
    "fontTypes": {
        "default": {
            "fontFamily": "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
            "fontSizes": {
                "small": 12,
                "default": 14,
                "medium": 14,
                "large": 18,
                "extraLarge": 24
            },
            "fontWeights": {
                "lighter": 200,
                "default": 400,
                "bolder": 600
            }
        },
        "monospace": {
            "fontFamily": "'Courier New', Courier, monospace",
            "fontSizes": {
                "small": 12,
                "default": 14,
                "medium": 14,
                "large": 18,
                "extraLarge": 24
            },
            "fontWeights": {
                "lighter": 200,
                "default": 400,
                "bolder": 600
            }
        }
    },
    "textStyles": {
        "heading": {
            "fontType": "default",
            "size": "large",
            "weight": "bolder",
            "color": "default",
            "isSubtle": false
        }
    },
    "textBlock": {
        "headingLevel": 2
    },
    "containerStyles": {
        "default": {
            "foregroundColors": {
                "default": {
                    "default": "#ff252424",
                    "subtle": "#bf252424"
                },
                "dark": {
                    "default": "#252424",
                    "subtle": "#bf252424"
                },
                "light": {
                    "default": "#ffffff",
                    "subtle": "#fff3f2f1"
                },
                "accent": {
                    "default": "#6264a7",
                    "subtle": "#8b8cc7"
                },
                "good": {
                    "default": "#92c353",
                    "subtle": "#e592c353"
                },
                "warning": {
                    "default": "#f8d22a",
                    "subtle": "#e5f8d22a"
                },
                "attention": {
                    "default": "#c4314b",
                    "subtle": "#e5c4314b"
                }
            },
            "borderColor": "#CCCCCC",
            "backgroundColor": "#ffffff"
        },
        "emphasis": {
            "foregroundColors": {
                "default": {
                    "default": "#ff252424",
                    "subtle": "#bf252424"
                },
                "dark": {
                    "default": "#252424",
                    "subtle": "#bf252424"
                },
                "light": {
                    "default": "#ffffff",
                    "subtle": "#fff3f2f1"
                },
                "accent": {
                    "default": "#6264a7",
                    "subtle": "#8b8cc7"
                },
                "good": {
                    "default": "#92c353",
                    "subtle": "#e592c353"
                },
                "warning": {
                    "default": "#f8d22a",
                    "subtle": "#e5f8d22a"
                },
                "attention": {
                    "default": "#c4314b",
                    "subtle": "#e5c4314b"
                }
            },
            "borderColor": "#666666",
            "backgroundColor": "#fff9f8f7"
        },
        "accent": {
            "borderColor": "#62A8F7",
            "backgroundColor": "#C7DEF9",
            "foregroundColors": {
                "default": {
                    "default": "#ff252424",
                    "subtle": "#bf252424"
                },
                "dark": {
                    "default": "#252424",
                    "subtle": "#bf252424"
                },
                "light": {
                    "default": "#ffffff",
                    "subtle": "#fff3f2f1"
                },
                "accent": {
                    "default": "#6264a7",
                    "subtle": "#8b8cc7"
                },
                "good": {
                    "default": "#92c353",
                    "subtle": "#e592c353"
                },
                "warning": {
                    "default": "#f8d22a",
                    "subtle": "#e5f8d22a"
                },
                "attention": {
                    "default": "#c4314b",
                    "subtle": "#e5c4314b"
                }
            }
        },
        "good": {
            "borderColor": "#69E569",
            "backgroundColor": "#CCFFCC",
            "foregroundColors": {
                "default": {
                    "default": "#ff252424",
                    "subtle": "#bf252424"
                },
                "dark": {
                    "default": "#252424",
                    "subtle": "#bf252424"
                },
                "light": {
                    "default": "#ffffff",
                    "subtle": "#fff3f2f1"
                },
                "accent": {
                    "default": "#6264a7",
                    "subtle": "#8b8cc7"
                },
                "good": {
                    "default": "#92c353",
                    "subtle": "#e592c353"
                },
                "warning": {
                    "default": "#f8d22a",
                    "subtle": "#e5f8d22a"
                },
                "attention": {
                    "default": "#c4314b",
                    "subtle": "#e5c4314b"
                }
            }
        },
        "attention": {
            "borderColor": "#FF764C",
            "backgroundColor": "#FFC5B2",
            "foregroundColors": {
                "default": {
                    "default": "#ff252424",
                    "subtle": "#bf252424"
                },
                "dark": {
                    "default": "#252424",
                    "subtle": "#bf252424"
                },
                "light": {
                    "default": "#ffffff",
                    "subtle": "#fff3f2f1"
                },
                "accent": {
                    "default": "#6264a7",
                    "subtle": "#8b8cc7"
                },
                "good": {
                    "default": "#92c353",
                    "subtle": "#e592c353"
                },
                "warning": {
                    "default": "#f8d22a",
                    "subtle": "#e5f8d22a"
                },
                "attention": {
                    "default": "#c4314b",
                    "subtle": "#e5c4314b"
                }
            }
        },
        "warning": {
            "borderColor": "#FFBC51",
            "backgroundColor": "#FFE2B2",
            "foregroundColors": {
                "default": {
                    "default": "#ff252424",
                    "subtle": "#bf252424"
                },
                "dark": {
                    "default": "#252424",
                    "subtle": "#bf252424"
                },
                "light": {
                    "default": "#ffffff",
                    "subtle": "#fff3f2f1"
                },
                "accent": {
                    "default": "#6264a7",
                    "subtle": "#8b8cc7"
                },
                "good": {
                    "default": "#92c353",
                    "subtle": "#e592c353"
                },
                "warning": {
                    "default": "#f8d22a",
                    "subtle": "#e5f8d22a"
                },
                "attention": {
                    "default": "#c4314b",
                    "subtle": "#e5c4314b"
                }
            }
        }
    },
    "actions": {
        "maxActions": 6,
        "spacing": "Default",
        "buttonSpacing": 8,
        "showCard": {
            "actionMode": "Inline",
            "inlineTopMargin": 16,
            "style": "emphasis"
        },
        "preExpandSingleShowCardAction": false,
        "actionsOrientation": "Horizontal",
        "actionAlignment": "Left"
    },
    "adaptiveCard": {
        "allowCustomStyle": false
    },
    "imageSet": {
        "imageSize": "Medium",
        "maxImageHeight": 100
    },
    "factSet": {
        "title": {
            "size": "Default",
            "color": "Default",
            "isSubtle": false,
            "weight": "Bolder",
            "warp": true
        },
        "value": {
            "size": "Default",
            "color": "Default",
            "isSubtle": false,
            "weight": "Default",
            "warp": true
        },
        "spacing": 16
    }
};

const endpointMap = {
    // Dev
    "56130ae1-5efd-40ca-b629-2c3da889bb32": "https://centro-api.baileycarlson.net/v1",
    // Production
    "0761e52a-4037-4dee-a41e-2cea8d27eb3e": "https://api.centro.rocks/v1",
    // Staging
    "3c837692-f74b-4717-b980-04ae3621ab9b": "https://staging.centro.rocks/v1"
};

const botIdMap = {
    // Dev
    "56130ae1-5efd-40ca-b629-2c3da889bb32": "269d23bc-b85b-4f21-b095-514273672622",
    // Production
    "0761e52a-4037-4dee-a41e-2cea8d27eb3e": "83ae83ff-2c6a-4daf-9e1f-759f4754e3c3",
    // Staging
    "3c837692-f74b-4717-b980-04ae3621ab9b": "6e811cf1-8b55-4735-b51c-a5cbc35e21d6"
};

class AdaptiveCardProxy extends Component {

    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.renderCard = this.renderCard.bind(this);
        this.submitAction = this.submitAction.bind(this);
    }

    fetchAdaptiveCard(type, entityId, userId, userDisplayName, tenantId, appId) {
        // Make a fake request like botbuilder does for legacy tab invoke events
        const endpoint = endpointMap[appId] || endpoint;
        const requestPayload = {
            "name": type,
            "type": "invoke",
            "from": {
                "name": userDisplayName,
                "aadObjectId": userId
            },
            "channelData": {
                "tenant": {
                    "id": tenantId
                },
            },
            "value": {
                "tabContext": {
                    "tabEntityId": entityId
                },
                "data": entityId
            }
        };
        return httpHelpers.request(endpoint + "/teams", "POST", JSON.stringify(requestPayload)).catch(e => {
            console.log("failed to retrieve adaptive card view from backend");
            console.log(e);
        });
    }

    async submitAction(action) {
        if (action instanceof window.AdaptiveCards.SubmitAction || action instanceof window.AdaptiveCards.ExecuteAction) {
            // Fetch card from lambdas before display dialog
            let response;
            try {
                response = await this.fetchAdaptiveCard(BOT_ACTION_NAME.TASK_FETCH, action.data, this.state.userId, this.state.userDisplayName, this.state.tenantId, this.state.appId);
            }
            catch (e) {
                console.log("Failed to fetch card");
                console.log(e);
            }
            if (action.data?.msteams?.type === "task/fetch") {
                // Open card dialogue if action has task/fetch msteams metadata in it
                let card = response.task?.value?.card?.content;
                const botId = botIdMap[this.state.appId] || "83ae83ff-2c6a-4daf-9e1f-759f4754e3c3";
                if (!card) card = errorCard;
                let taskInfo = {
                    title: action.title,
                    size: {
                        width: 520,
                    },
                    card: JSON.stringify(card),
                    completionBotId: botId,
                };
                dialog.adaptiveCard.bot.open(taskInfo, (err, result) => {
                    if (err) console.log(err);
                    if (result) console.log(result);
                });
            }
            else if (response?.tab) {
                // Update tab view if response offers a new tab card;
                const card = Array.isArray(response?.tab?.value?.cards) && response?.tab?.value?.cards[0] && response?.tab?.value?.cards[0].card;
                if (card) {
                    this.renderCard(card);
                }
            }
        }
        else if (action instanceof window.AdaptiveCards.OpenUrlAction) {
            window.open(action.url, "_blank");
        }
    }

    renderCard(card) {
        // Create an AdaptiveCard instance
        const adaptiveCard = new window.AdaptiveCards.AdaptiveCard();
        // Set its hostConfig property unless you want to use the default Host Config
        // Host Config defines the style and behavior of a card
        adaptiveCard.hostConfig = new window.AdaptiveCards.HostConfig(lightHostConfig);
        // Set the adaptive card's event handlers. onExecuteAction is invoked
        // whenever an action is clicked in the card
        adaptiveCard.onExecuteAction = this.submitAction;

        // For markdown support you need a third-party library
        // E.g., to use markdown-it, include in your HTML page:
        //     <script type="text/javascript" src="https://unpkg.com/markdown-it/dist/markdown-it.js"></script>
        // And add this code to replace the default markdown handler:
        window.AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
            result.outputHtml = window.markdownit().render(text);
            result.didProcess = true;
        };

        // Parse the card payload
        adaptiveCard.parse(card);
        // Render the card to an HTML element:
        while (this.container && this.container.current.firstChild) {
            this.container.current.removeChild(this.container.current.lastChild);
        }
        this.container.current.appendChild(adaptiveCard.render());
    }

    async componentDidMount() {
        let card;
        try {
            await app.initialize();
            const context = await app.getContext();
            if (context) {
                let appId = "56130ae1-5efd-40ca-b629-2c3da889bb32";
                // Get App Id from URL because it's not reliably passed in context
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                if (params && params.appId) {
                    appId = params.appId;
                }
                const {
                    "page": {
                        "id": tabId
                    },
                    "user": {
                        "id": userId,
                        "displayName": userDisplayName,
                        "tenant": {
                            "id": tenantId
                        }
                    },
                } = context;
                this.setState({
                    appId,
                    tenantId,
                    userDisplayName,
                    userId,
                    tabId
                });
                this.renderCard(loadingCard);
                const result = await this.fetchAdaptiveCard(BOT_ACTION_NAME.TAB_FETCH, tabId, userId, userDisplayName, tenantId, appId);
                card = result?.tab?.value?.cards?.[0]?.card;
            }
        }
        catch (e) {
            console.log(e);
        }

        if (card) {
            this.renderCard(card);
            app.notifySuccess();
        }
        else {
            app.notifyFailure({ reason: app.FailedReason.Timeout, message: "Couldn't load view from app." });
            this.renderCard(errorCard);
        }
    }

    render() {
        return <div className="fullscreen-scrollable">
            <div ref={this.container}></div>
        </div>
    }
}

export default AdaptiveCardProxy;
